<template>
  <div
    class="container px-2 py-4 w-full"
    :class="dnb ? 'dnb' : 'pep'"
    @click="openEditCanvas"
  >
    <div class="flex justify-between items-center">
      <div class="mx-1 flex justify-center">
        <span
          class="text-sm break-all"
          :class="dnb ? 'font-bold' : 'font-medium'"
          :title="this.individualData.entity"
        >
          {{
            this.individualData?.entity.length > 15
              ? `${this.individualData?.entity.substr(0, 15)}...`
              : this.individualData?.entity
          }}
        </span>
        
        <span
          v-if="individualData?.entity_meta_data?.inactive"
          :title="individualData?.entity_meta_data?.inactive ? 'Inactive' : ''"
          class="mx-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
        >
          Inactive
        </span>
        <span
          v-else-if="individualData?.entity_meta_data?.inactive === false"
          :title="!individualData?.entity_meta_data?.inactive ? 'Active' : ''"
          class="mx-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
        >
          Active
        </span>
        
        <span
        v-if="individualData?.entity_meta_data?.current_status && individualData?.entity_meta_data?.current_status  !== 'Active' && individualData?.entity_meta_data?.current_status  !== 'Inactive' "
          class="flex flex-wrap mr-6 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
        >
          {{ individualData?.entity_meta_data?.current_status }}
        </span>
        <div v-if="dnb" class="flex text-xl items-center ml-3">
          <span
            class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
          >
            {{ companyDetail?.dunsControlStatus?.operatingStatus?.description }}
          </span>
        </div>
      </div>
      <div class="flex items-center">
        <CanvasMenu
          class="cursor-pointer"
          :confidence="true"
          :individualData="getIndividualData"
          :mainEntity="mainEntity"
          :sourceName="sourceName"
          title="Identifiers"
        />

        <div
          class="mx-1 label-box dropdown_parent"
          :class="this.status"
          title="Status"
        >
          <span
            class="label"
            @click="toogleDropdown"
            @blur="closeDropdown"
            tabindex="0"
          >
            <Check v-if="this.status == 'confirmed'" class="cursor-pointer" />
            <Question
              v-else-if="this.status == 'potential'"
              class="cursor-pointer"
            />
            <Cross
              v-else-if="this.status == 'irrelevant'"
              class="cursor-pointer"
            />
            <MinusIcon v-else class="cursor-pointer" />
          </span>
          <ul
            v-if="showList"
            class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2"
          >
            <li
              @mousedown="selectDropdown"
              name="confirmed"
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
            >
              <span name="confirmed" class="" style="color: #67b52b"
                >Confirmed</span
              >
              <span name="confirmed" class="badge" style="background: #f0f8ea">
                <Check name="confirmed" />
              </span>
            </li>
            <li
              @mousedown="selectDropdown"
              name="potential"
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
            >
              <span name="potential" class="" style="color: #fc6713"
                >Potential</span
              >
              <span
                name="potential"
                class="badge"
                style="background: rgba(252, 103, 19, 0.11)"
              >
                <Question name="potential" />
              </span>
            </li>
            <li
              @mousedown="selectDropdown"
              name="irrelevant"
              class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
            >
              <span name="irrelevant" class="" style="color: #eb3131"
                >Irrelevant</span
              >
              <span name="irrelevant" class="badge" style="background: #fbeded">
                <Cross name="irrelevant" />
              </span>
            </li>
            <li
              @mousedown="selectDropdown"
              name="unknown"
              class="p-2 flex items-center justify-between"
            >
              <span name="unknown" class="" style="color: #8d8d8d"
                >unknown</span
              >
              <span name="unknown" class="badge" style="background: #d7d7d7">
                <MinusIcon name="unknown" />
              </span>
            </li>
          </ul>
        </div>
        <div class="mx-1" title="Risk">
          <Exclamation class="cursor-pointer" :fill="this.riskcolor" />
        </div>
        <div class="hidden">
          <!-- <Threedot /> -->
        </div>
        <div class="mx-1">
          <CanvasMenu
            ref="editCanvasMenuRef"
            class="cursor-pointer"
            :individualData="getIndividualData"
            :mainEntity="mainEntity"
            :sourceName="sourceName"
            :isEditCanvas="editCanvas"
            @stateClear="updateSelectedPage"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col" v-if="corporateRecords">
      <div class="flex py-2 mx-1">
        <span class="text-xs">{{ individualData?.entity_meta_data?.registered_address_in_full }}</span>
      </div>
      <div class="flex text-gray-600 text-sm mx-1">
        <div class="w-1/2 flex-col">
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Company Number:</div>
            <div class="max-w-1/2 flex text-black">
              <span>{{ individualData?.entity_meta_data?.company_number }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Company Type:</div>
            <div class="max-w-1/2 flex text-black">
              <span>{{ individualData?.entity_meta_data?.company_type }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Jurisdiction:</div>
            <div class="max-w-1/2 flex text-black">{{ individualData?.entity_meta_data?.code }}</div>
          </div>
        </div>
        <div class="flex w-1/2 flex-col">
          <div class="flex gap-2">
            <div class="flex">Incorporation Date:</div>
            <div class="flex text-black">
              <span>{{ individualData?.entity_meta_data?.incorporation_date || '-' }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="flex">Last Update Date:</div>
            <div class="flex text-black">{{ individualData?.entity_meta_data?.updated_at || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dnb" class="mt-1 mx-1 w-full text-gray-600 text-sm">
      <span v-if="this.individualData?.entity_meta_data?.company_detail">
        {{
          companyDetail?.primaryAddress?.streetAddress.line1 +
          ", " +
          companyDetail?.primaryAddress?.addressLocality.name +
          ", " +
          companyDetail?.primaryAddress?.addressCountry.name +
          " " +
          companyDetail?.primaryAddress?.postalCode
        }}
      </span>
      <div class="grid grid-cols-2 gap-2 w-full">
        <div class="">
          <div>
            <span>Company Number : </span>
            <span>{{
              this.individualData?.entity_meta_data?.registrationNumbers
            }}</span>
          </div>
          <div>
            <span>Registry : </span>
            <span></span>
          </div>
          <div>
            <span>Incorporation Date : </span>
            <span>{{ companyDetail?.incorporatedDate }}</span>
          </div>
        </div>
        <div class="">
          <div>
            <span>More Senior Contact : </span>
            <span
              :title="
                this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]
                  ?.fullName
              "
              >{{
                this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]
                  ?.fullName
                  ? this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName.substr(
                      0,
                      6
                    ) + "..."
                  : ""
              }}</span
            >
          </div>
          <div>
            <span>Company Type : </span>
            <span></span>
          </div>
          <div>
            <span>Last Updated Date : </span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col" v-if="corporateRecords">
      <div class="flex py-2 mx-1">
        <span class="text-xs">{{ individualData?.entity_meta_data?.registered_address_in_full }}</span>
      </div>
      <div class="flex text-gray-600 text-sm mx-1">
        <div class="w-1/2 flex-col">
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Company Number:</div>
            <div class="max-w-1/2 flex text-black">
              <span>{{ individualData?.entity_meta_data?.company_number }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Company Type:</div>
            <div class="max-w-1/2 flex text-black">
              <span>{{ individualData?.entity_meta_data?.company_type }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="max-w-1/2 flex">Jurisdiction:</div>
            <div class="max-w-1/2 flex text-black">{{ individualData?.entity_meta_data?.code }}</div>
          </div>
        </div>
        <div class="flex w-1/2 flex-col">
          <div class="flex gap-2">
            <div class="flex">Incorporation Date:</div>
            <div class="flex text-black">
              <span>{{ individualData?.entity_meta_data?.incorporation_date || '-' }}</span>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="flex">Last Update Date:</div>
            <div class="flex text-black">{{ individualData?.entity_meta_data?.updated_at || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dnb" class="mt-1 mx-1 w-full text-gray-600 text-sm">
      <span v-if="this.individualData?.entity_meta_data?.company_detail">
        {{
          companyDetail?.primaryAddress?.streetAddress.line1 +
          ", " +
          companyDetail?.primaryAddress?.addressLocality.name +
          ", " +
          companyDetail?.primaryAddress?.addressCountry.name +
          " " +
          companyDetail?.primaryAddress?.postalCode
        }}
      </span>
      <div class="grid grid-cols-2 gap-2 w-full">
        <div class="">
          <div>
            <span>Company Number : </span>
            <span>{{
              this.individualData?.entity_meta_data?.registrationNumbers
            }}</span>
          </div>
          <div>
            <span>Registry : </span>
            <span></span>
          </div>
          <div>
            <span>Incorporation Date : </span>
            <span>{{ companyDetail?.incorporatedDate }}</span>
          </div>
        </div>
        <div class="">
          <div>
            <span>More Senior Contact : </span>
            <span
              :title="
                this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]
                  ?.fullName
              "
              >{{
                this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]
                  ?.fullName
                  ? this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName.substr(
                      0,
                      6
                    ) + "..."
                  : ""
              }}</span
            >
          </div>
          <div>
            <span>Company Type : </span>
            <span></span>
          </div>
          <div>
            <span>Last Updated Date : </span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dnb" class="mx-1 w-full">
        {{ 
           companyDetail?.primaryAddress?.streetAddress.line1 +", " +
           companyDetail?.primaryAddress?.addressLocality.name + ", "+
           companyDetail?.primaryAddress?.addressCountry.name +" "+
           companyDetail?.primaryAddress?.postalCode
        }}
        <div class="grid grid-cols-2 gap-2 w-full">
            <div class="">
              <div> 
                <span>Company Number : </span>
                <span>{{this.individualData?.entity_meta_data?.registrationNumbers}}</span>
              </div>  
              <div> 
                <span>Registry : </span>
                <span></span>
              </div>  
              <div> 
                <span>Incorporation Date : </span>
                <span>{{companyDetail?.incorporatedDate}}</span>
              </div>  
            </div> 
            <div class="">
              <div> 
                <span>More Senior Contact : </span>
                <span :title="this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName">{{this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName ? this.individualData?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName.substr(0,6)+'...' : ''}}</span>
              </div>  
              <div> 
                <span>Company Type : </span>
                <span>{{ }}</span>
              </div>  
              <div> 
                <span>Last Updated Date : </span>
                <span>{{ }}</span>
              </div>  
            </div>   
        </div>  
    </div>
  </div> 
</template>
<script>
import Exclamation from "@shared/assets/exclamation-red.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
// import Threedot from '../../assets/svg/threedot.svg';
import CanvasMenu from "../../components/canvas-menu";
import Check from "../../assets/svg/check.svg";
import Question from "../../assets/svg/question.svg";
import Cross from "../../assets/svg/cross-red.svg";
import { updateEntityDetails } from "../../services";

export default {
  components: {
    Exclamation,
    MinusIcon,
    // Threedot,
    CanvasMenu,
    Check,
    Question,
    Cross,
  },
  props: {
    mainEntity: Array,
    individualData: Object,
    sourceName: String,
    dnb: Boolean,
    corporateRecords: Boolean,
  },
  data() {
    return {
      showList: false,
      status: "unknown",
      editCanvas: false,
    };
  },
  methods: {
    updateSelectedPage(page) {
      this.$emit("stateClear", page);
    },
    toogleDropdown(e) {
      e.stopPropagation();
      this.showList = !this.showList;
    },
    closeDropdown() {
      this.showList = false;
    },
    async selectDropdown(event) {
      this.showList = false;
      let key = event.target.innerText;
      this.status = key.toLowerCase();
      this.individualData.status = this.status.toUpperCase();
      let payload = {
        ...this.individualData,
        case_id: this.$route.query.case_id,
      };
      let data = await updateEntityDetails(payload, this.individualData._id);
      this.$emit("updateFilterState");
      if (data) {
        this.$toast.success("Status updated");
      }
    },
    openEditCanvas() {
      this.editCanvas = !this.editCanvas;
    },
  },
  computed: {
    companyDetail() {
      return this.individualData?.entity_meta_data?.company_detail
        ?.organization;
    },

    riskcolor() {
      let c =
        this.individualData?.risk_categories.length > 0
          ? this.individualData?.entity_status === "Red"
            ? `#EB3131`
            : `rgb(235, 155, 0)`
          : `#8D8D8D`;
      return c;
    },
    getIndividualData() {
      return this.individualData;
    },
    confidenceColor() {
      return (this.individualData?.confidence_score?.name).toLowerCase();
    },
  },
  mounted() {
  },
  created() {
    this.status = this.individualData.status.toLowerCase() || "unknown";
  },
};
</script>
<style lang="scss" scoped>
.container {
  border-radius: 12px;
  border: 1px solid var(--Gray, #e9e9ea);
  background: #fff;
}
.container:hover {
  border-color: #0d69d5;
  cursor: pointer;
}
.dnb {
  height: auto;
}
// .pep{
//   height: 4rem;
// }
.badge {
  display: flex;
  width: 25px;
  height: 25px;
  // padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0px;
}

.label-box {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.label {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.unknown {
  border-radius: 30px;
  background: #d7d7d7;
}
.confirmed {
  border-radius: 30px;
  background: #f0f8ea;
}
.potential {
  border-radius: 30px;
  background: rgba(252, 103, 19, 0.11);
}
.irrelevant {
  border-radius: 30px;
  background: #fbeded;
}
.high {
  font-size: 12px;
  font-weight: 400;
  color: #67b52b !important;
  background-color: #e1f3d3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  background-color: white;
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  right: 0%;
  z-index: 9999 !important;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

  li:hover {
    background-color: #d7d7d7 !important;
  }
}
</style>
