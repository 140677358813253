<template>
    <div class="relationship-main-container">
        <h2>Relationships</h2>
        <div class="relationship-container" id="middle">
            <div class="flex flex-row ">
                <div class="flex flex-col  mr-2">
                    <label for="first_name" class="r_label">Related to</label>
                    <select class="form-select select text-gray-700  bg-white bg-clip-padding bg-no-repeat
                         border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none  my-2" v-model="data.entity" placeholder="Select"
                        @change="handleChange" name="entity">
                        <option disabled :value="null" hidden>Select Person</option>
                        <option v-for="option, idx in getMainentity" :key="idx" :value="option._id">
                            {{ option.entity }}
                        </option>
                    </select>
                </div>
                <div class=" flex flex-col ml-2">
                    <label for="first_name" class="r_label">Relation type</label>
                    <select class="form-select select text-gray-700 
                       bg-white bg-clip-padding bg-no-repeat border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none  my-2" v-model="data.relation_type" placeholder="Select"
                        name="relation_type" @change="handleChange">
                        <option disabled :value="null" hidden>Select Relation</option>
                        <option v-for="option, idx in getRelations" :key="idx" :value="option._id">
                            {{ option.label }}
                        </option>
                    </select>
                </div>
                <Button type="primary" v-if="this.buttonType == 'Add'" class="button_custom mt-5 mb-2 ml-2"
                    :disabled="this.loadingBtn" @click="addData" :loader="this.loadingBtn">{{ this.buttonType }}
                </Button>
                <Button type="primary" v-if="this.buttonType == 'Update'" class="button_custom mt-5 mb-2 ml-2"
                    :disabled="this.loadingBtn" @click="updateData" :loader="this.loadingBtn">{{ this.buttonType }}
                </Button>
            </div>
            <div class="flex flex-row w-full py-4 ">
                <div class="flex flex-col w-full">
                    <label for="first_name" class="r_label">Line type</label>
                    <div class="flex flex-row w-full my-2">
                        <div class="svg-wrapper" id="solid" @click="setLineType($event)"
                            :class="{ 'selected': data.line_type === 'solid' }">
                            <BoldLine />
                        </div>
                        <div class="svg-wrapper" id="dashed" @click="setLineType($event)"
                            :class="{ 'selected': data.line_type === 'dashed' }">
                            <DashedLine />
                        </div>
                        <div class="svg-wrapper" id="dotted" @click="setLineType($event)"
                            :class="{ 'selected': data.line_type === 'dotted' }">
                            <DottedLine />
                        </div>
                    </div>

                </div>
                <div class="flex flex-col w-full ml-4">
                    <label for="first_name" class="r_label">Arrow type</label>
                    <div class="flex flex-row w-full my-2">
                        <div class="svg-wrapper" id="arrow_none" @click="setArrowType($event)"
                            :class="{ 'selected': this.data.arrow_type === 'arrow_none' }">
                            <BoldLine />
                        </div>
                        <div class="svg-wrapper" id="arrow_from" @click="setArrowType($event)"
                            :class="{ 'selected': this.data.arrow_type === 'arrow_from' }">
                            <ArrowLeft />
                        </div>
                        <div class="svg-wrapper" id="arrow_bi" @click="setArrowType($event)"
                            :class="{ 'selected': this.data.arrow_type === 'arrow_bi' }">
                            <ArrowLeftRight />
                        </div>
                        <div class="svg-wrapper" id="arrow_to" @click="setArrowType($event)"
                            :class="{ 'selected': this.data.arrow_type === 'arrow_to' }">
                            <ArrowRight />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-start ">
                <label for="toogle" class="flex items-center cursor-pointer">

                    <div class="relative">
                        <input id="toogle" type="checkbox" v-model="data.showGraph" class="sr-only" />
                        <div class="w-8 h-3 bg-red-100 rounded-full shadow-inner"></div>
                        <div class=" dot absolute w-5 h-5 bg-white rounded-full shadow -left-1 -top-1 transition">
                        </div>
                    </div>
                    <div class="label ml-3 ">
                        Show on Graph
                    </div>
                </label>
            </div>
        </div>
        <div class="devider">
            <Devider />
        </div>
        <h2>Previous Relationships</h2>
        <div class="relationship-container" id="middle">
            <div class="flex flex-row w-full my-2" v-for=" (relation, index) of this.individualData.relations" :key="index">
                <div class="flex flex-col w-full mr-2">
                    <input :value="getEntityName(relation)" type="text" id="relation_name" class="input"
                        :class="{ 'disabled': true }" :disabled="true" />
                </div>
                <div class=" flex flex-col w-full mr-2">
                    <input :value="getRelationName(relation)" type="text" id="realtion-type" class="input"
                        :class="{ 'disabled': true }" :disabled="true" />
                </div>
                <div class=" flex flex-row w-full mr-2 justify">
                    <div class="mr-2">
                        <BoldLine v-if="relation.line_type === 'solid'" />
                        <DottedLine v-if="relation.line_type === 'dotted'" />
                        <DashedLine v-if="relation.line_type === 'dashed'" />
                    </div>
                    <div>
                        <ArrowRight v-if="relation.arrow_type == 'arrow_to'" />
                        <BoldLine v-if="relation.arrow_type == 'arrow_none'" />
                        <ArrowLeft v-if="relation.arrow_type == 'arrow_from'" />
                        <ArrowLeftRight v-if="relation.arrow_type == 'arrow_bi'" />
                    </div>
                </div>
                <div class=" flex flex-row w-full">
                    <div class="edit mr-2" @click="handleEdit(relation)">
                        <EditGreen /> <span class="btn-label success">Edit</span>
                    </div>
                    <div class="delete" @click="handleDelete(relation)">
                        <TrashRed /> <span class="btn-label danger">Delete</span>
                    </div>
                </div>
            </div>


        </div>
        <div class="devider">
            <Devider />
        </div>
        <h2 class="hidden">Relationships</h2>
        <div class="flex flex-col w-full hidden">
            <select class="form-select text-gray-700 
                       bg-white bg-clip-padding bg-no-repeat border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none py-1 my-2" v-model="data.realtedTo"
                placeholder="Select">
                <option value="undefined" disabled>Select Output</option>
                <option v-for="option, idx in getRiskOptions" :key="idx" :value="option.entity">
                    {{ option.entity }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
import BoldLine from '../../assets/svg/line.svg'
import ArrowLeft from '../../assets/svg/arrow-left.svg'
import ArrowRight from '../../assets/svg/arrow-right.svg'
import ArrowLeftRight from '../../assets/svg/arrow-left-right.svg'
import DashedLine from '../../assets/svg/dashed-line.svg'
import DottedLine from '../../assets/svg/dotted-line.svg'
import Devider from '../../assets/svg/devider.svg'
import EditGreen from '../../assets/svg/greenEdit.svg';
import TrashRed from '../../assets/svg/trash-red.svg';
import { getrelationAttributes, updateEntityDetails, updateInternetDataDetails } from '../../services'


export default {
    name: "Relationships",
    components: {
        BoldLine,
        DashedLine,
        DottedLine,
        ArrowLeft,
        ArrowLeftRight,
        ArrowRight,
        Devider,
        EditGreen,
        TrashRed
    },
    props: {
        mainEntity: Array,
        individualData: Object,
        sourceName:String
    },
    data() {
        return {
            edit_id: null,
            initial: {
                entity: null,
                relation_type: null,
                show_on_graph: true,
                line_type: "solid",
                arrow_type: "arrow_from",
                attributes_data: [],
                deleted: false,
                _id: null,
                entity_name: "",
                relation_label: ""
            },
            loadingBtn: false,
            relationTypes: [],
            buttonType: 'Add',
            data: {
                entity: null,
                relation_type: null,
                show_on_graph: true,
                line_type: "solid",
                arrow_type: "arrow_from",
                attributes_data: [],
                deleted: false,
                _id: null,
                entity_name: "",
                relation_label: ""
            }
        };
    },
    methods: {
        handleChange(e) {
            e.currentTarget.name = e.currentTarget.value
        },
        handleEdit(relation) {
            this.buttonType = 'Update',
                this.data.entity = relation.entity,
                this.data.relation_type = relation.relation_type._id,
                this.data.show_on_graph = relation.show_on_graph,
                this.data.line_type = relation?.line_type,
                this.data.arrow_type = relation?.arrow_type,
                this.data.deleted = relation?.deleted,
                this.data._id = relation?._id
        },
        async handleDelete(relation) {
            this.individualData.relations = this.individualData.relations.filter((item) => (item._id != relation._id));
            let payload = { ...this.individualData }
            payload.case_id = this.$route.query.case_id
            let response;
            if(this.sourceName=='internet'){
             response=await updateInternetDataDetails(payload, this.individualData?._id)
            }
            else{
            response=await updateEntityDetails(payload, this.individualData?._id)
            }
            this.individualData = response.data.data;
            this.$emit('setIndividualData', response.data.data)
            this.loadingBtn = false
            this.$toast.success("Deleted Sucessfully");
        },
        async addData() {
            if (this.data.entity !== null  && this.data.entity != '' && this.data.relation_type !== null  && this.data.relation_type != '') {
                this.loadingBtn = true;
                this.individualData.relations.push(this.data);
                let payload = { ...this.individualData }
                payload.case_id = this.$route.query.case_id

                let response;
                if(this.sourceName=='internet'){
                  response=await updateInternetDataDetails(payload, this.individualData?._id)
                }
                else{
                  response = await updateEntityDetails(payload, this.individualData?._id)
                }
                // this.data = JSON.parse(JSON.stringify(this.initial));
                this.resetInitialRelationState()
                if (response?.data?.data) {
                    this.individualData = response?.data?.data;
                    this.$emit('setIndividualData', response.data.data)
                    this.loadingBtn = false;
                    this.$toast.success("Added sucessfully")
                }
                else {
                    this.$toast.error("Something wents wrong");
                    this.individualData.relations.pop();
                    this.loadingBtn = false;
                }
            }
            else {
                this.$toast.error("please select all Field")
            }
        },
        resetInitialRelationState() {
            this.data = JSON.parse(JSON.stringify(this.initial))
        },
        async updateData() {
            this.individualData.relations = this.individualData.relations.map((item) => {
                if (item._id === this.data._id) {
                    return this.data;
                } else {
                    return item
                }
            });
            let payload = { ...this.individualData }
            payload.case_id = this.$route.query.case_id
            let response;
                if(this.sourceName=='internet'){
                  response=await updateInternetDataDetails(payload, this.individualData?._id)
                }
                else{
                  response = await updateEntityDetails(payload, this.individualData?._id)
                }
            this.resetInitialRelationState()
            this.$emit('setIndividualData', response.data.data)
            this.individualData = response.data.data;
            this.loadingBtn = false;
            this.$toast.success("Updated Sucessfully");
            this.buttonType = 'Add'
        },
        async relationshipType() {
            let data = await getrelationAttributes();
            this.relationTypes = data?.data?.data.relations.filter(item => item.show_on_edge);
        },
        setLineType(e) {
            this.data.line_type = e.currentTarget.id
        },
        setArrowType(e) {
            this.data.arrow_type = e.currentTarget.id
        },
        getEntityName(relation) {
            const selectedData = this.mainEntity.find((item) => item._id === relation.entity);
            return selectedData ? selectedData.entity : '';
        },
        getRelationName(relation) {
            if (this.relationTypes.length) {
                const relationData = this.relationTypes.find((item) => item._id === relation.relation_type?._id)
                return relationData ? relationData.label : " ";
            }
        }

    },
    created() {
        // this.relationshipType();
    },
    mounted() {
        this.relationshipType();
    },
    computed: {
        getRelations() {
            return this.relationTypes;
        },
        getMainentity() {
            return [...this.mainEntity];
        },
        getRiskOptions() {
            return [
                {
                    entity: 'Risk One',
                    checked: true
                },
                {
                    entity: 'Risk two',
                    checked: true
                },
                {
                    entity: 'Risk three',
                    checked: true
                },
                {
                    entity: 'Risk four',
                    checked: true
                }
            ]
        },

    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/color.scss";

.button_custom {
    display: flex;
    padding: 10px 30px;
    align-items: flex-start;
    gap: 10px;
    border-color: $lightray_product_color;
    border-radius: 6px;
    color: white !important;
    background-color: $lightray_product_color;
}

.relationship-container {
    /* display: flex; */
    padding: 24px;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    gap: 24px;
    align-self: stretch;
    background: #FAFAFC;
}

.r_label {
    color: var(--blue-shade, #1A2036) !important;
    font-family: Poppins;
    font-size: 11px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 109.091% */
}

.svg-wrapper {
    border-radius: 10px;
    margin-right: 10px;
}

.svg-wrapper.selected {
    border: 3px solid #910000;
}

.parent {
    input[type="radio"] {
        height: 15px;
        appearance: auto;
    }
}

.dot {
    background-color: #e4e1e1;
}

input:checked~.dot {
    transform: translateX(100%);
    background-color: #900009;
}

.label {
    color: var(--blue-shade, #1A2036);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.devider {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.input {
    height: 2.3rem !important;
    border-radius: 8px !important;
    border: 1px solid var(--grey, #AEB1BA) !important;
    background: var(--white, #FFF) !important;
    width: 100%;
}

.edit {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 8px;
    color: #42B85C;
    width: 100px;
    border: 0.5px solid var(--light-ray-action-red, #42B85C);
    border-radius: 8px;
}

.delete {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 0.5px solid var(--light-ray-action-red, #CE1B22);
    background: var(--white, #FFF);
    color: #EB3131;
    width: 100px;
}

.btn-label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.btn-label.danger {
    color: #EB3131;
}

.btn-label.success {
    color: #42B85C;
}

.disabled {
    background: var(--fill-grey, #F3F4F6) !important;

}

// .relationship-main-container {
//     max-height: 80vh;
//     overflow: scroll;

// }

.select {
    border-radius: 8px;
    border: 0.5px solid var(--grey, #AEB1BA);
    width: 27vw;
}
</style>
