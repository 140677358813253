<template>
    <!-- <div class="relative w-[19.875rem]  cursor-pointer ring-blue-100  rounded-lg bg-white text-[0.8125rem] leading-5 text-slate-900 shadow-xl shadow-black/5 ring  hover:ring-blue-500" >
    <font-awesome-icon icon="pencil" />
    <button v-if="edit==true" class="bg-blue-700 text-white-text text-sm px-2 py-2 absolute rounded-lg bottom-2 right-2" @click="editPackage(tenant_package.id)">configure </button>
    <button v-else class="bg-blue-700 text-white-text text-sm px-2 py-2 absolute rounded-lg bottom-2 right-2" @click="openCaseCreateForm(tenant_package.id)">create case </button>

    <div class="flex items-center p-4 pb-0 ">
        <div class="ml-4 flex-auto relative">
            <div class="text-[0.8125rem] font-semibold leading-5 text-black " >{{tenant_package.package_name}}</div>
            <div v-if="tenant_package.mark_as_default==true" class="absolute text-xs text-gray-600 top-0 right-0 bg-purple-200 py-1 px-2 rounded-md">default</div>
            <div class="mt-1 text-black text-sm">{{tenant_package.package_description}}</div>
        </div>
    </div>
    <div class="flex gap-3 p-4">
        <div v-if="tenant_package.turn_around_time>=1" class="pointer-events-auto rounded-md py-2 px-3 ">
            {{tenant_package.turn_around_time}} days
        </div>
        <div v-else class="pointer-events-auto rounded-md py-2 px-3 text-xs text-gray-500">
              No SLA set for this package
        </div>
    </div>
  </div> -->

    <div class="card bg-card-bg shadow-sm h-10">
        <div class="card-body p-6 text-base-content">
            <div class="flex items-center justify-between">
                <h2 class="card-title text-base mr-2  truncate flex-1 " :title="tenant_package.package_name || 'No title'">
                    {{ tenant_package.package_name || "No title" }}
                </h2>
                <span v-allow="'package.read'" v-if="$route.name === 'Screening Package'" @click.stop="editPackage($event, tenant_package.id, 'view')" title="View package" class="h-8 items-center mr-3 justify-center flex rounded-half w-8 bg-gray-200 hover:bg-gray-300 flex-shrink-0">
                    <font-awesome-icon icon="eye" class="text-base-content fill-current ease-in-out transition-all duration-100 w-24 text-md self-center cursor-pointer" />
                </span>
                <span v-allow="'package.create'" v-if="$route.name === 'Screening Package'" @click.stop="showCloneModal(tenant_package)" title="Clone package" class="h-8 items-center justify-center flex rounded-half w-8 bg-gray-200 hover:bg-gray-300 flex-shrink-0">
                    <font-awesome-icon icon="clone" class="text-base-content fill-current ease-in-out transition-all duration-100 w-24 text-md self-center cursor-pointer" />
                </span>
            </div>
            <p class="text-sm mb-2 flex-grow line-clamp-3">{{ tenant_package.package_description }}</p>
            <span class="badge badge-info" v-if="tenant_package.automated">Automated</span>
            <div class="card-actions mt-auto flex-nowrap gap-4 items-center justify-between">
                <p class="text-xs text-base-content-300 m-0 pl-1 items-center">
                    {{ tenant_package.turn_around_time >= 1 ? tenant_package.turn_around_time + " days" : "No SLA set for this package" }}
                </p>
                
                <!-- <button
                    class="btn btn-sm rounded-xl normal-case btn-outline btn-primary"
                    @click="editPackage(tenant_package.id, 'edit')">
                    <span>
                        <button class="disabled:opacity-50">
                            <svg width="15.75" height="18.375" viewBox="0 0 15.75 18.375" fill="none" version="1.1"
                                id="svg4" sodipodi:docname="0edb01c6933bcd8f0ece7fd255220d19.svg"
                                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
                                <defs id="defs8" />
                                <sodipodi:namedview id="namedview6" pagecolor="#ffffff" bordercolor="#666666"
                                    borderopacity="1.0" inkscape:pageshadow="2" inkscape:pageopacity="0.0"
                                    inkscape:pagecheckerboard="0" />
                                <path
                                    d="m 2.625,7.875 v 8.75 H 14 v -8.75 z m 10.5,-1.75 h 1.75 c 0.4833,0 0.875,0.39175 0.875,0.875 v 10.5 c 0,0.4833 -0.3917,0.875 -0.875,0.875 h -14 C 0.39175,18.375 0,17.9833 0,17.5 V 7 C 0,6.51675 0.39175,6.125 0.875,6.125 h 1.75 V 5.25 C 2.625,2.3505 4.9755,0 7.875,0 c 2.8995,0 5.25,2.3505 5.25,5.25 z m -1.75,0 V 5.25 c 0,-1.933 -1.567,-3.5 -3.5,-3.5 -1.933,0 -3.5,1.567 -3.5,3.5 V 6.125 Z M 3.5,8.75 H 5.25 V 10.5 H 3.5 Z m 0,2.625 h 1.75 v 1.75 H 3.5 Z M 3.5,14 h 1.75 v 1.75 H 3.5 Z"
                                    fill="#0d69d5" id="path2" />
                            </svg>
                        </button>
                    </span>

                    <span class="ml-3">{{ tenant_package?.modified_by ? tenant_package?.modified_by : 'Lock' }}</span>
                </button>
               -->
               <div v-if="edit">
                <button  v-if="tenant_package?.lock == true" class="btn btn-sm rounded-xl normal-case"
                   v-tippy="{ content: 'Package in use' }"
                   :class="{
                            'btn-primary': !tenant_package.lock && !buttonVisible,
                            'btn-outline border-none': tenant_package.lock,
                            'disabled-button': tenant_package.lock,
                            'buttonvisbility border-none':buttonVisible
                            }"
                    :style="{ color: tenant_package.lock ? 'black' : '' }" @click="editPackage($event,tenant_package.id, 'edit')">
                    
                    <span v-if="tenant_package?.lock == true" class="mr-3">
                        <button v-tippy="{ content: 'Package in use'}">
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.75 6.25V12.5H10.875V6.25H2.75ZM10.25 5H11.5C11.8452 5 12.125 5.27983 12.125 5.625V13.125C12.125 13.4702 11.8452 13.75 11.5 13.75H1.5C1.15483 13.75 0.875 13.4702 0.875 13.125V5.625C0.875 5.27983 1.15483 5 1.5 5H2.75V4.375C2.75 2.30393 4.42893 0.625 6.5 0.625C8.57106 0.625 10.25 2.30393 10.25 4.375V5ZM9 5V4.375C9 2.99429 7.88069 1.875 6.5 1.875C5.11929 1.875 4 2.99429 4 4.375V5H9ZM3.375 6.875H4.625V8.125H3.375V6.875ZM3.375 8.75H4.625V10H3.375V8.75ZM3.375 10.625H4.625V11.875H3.375V10.625Z" fill="#11142D" fill-opacity="0.92"/>
                            </svg>
                        </button>
                    </span>
                   {{tenant_package?.modified_by}}
                </button>
                <div v-else>
                 <router-link
                    :to="{ 
                        name: 'Screening Package View', 
                        params: { packageName: tenant_package.id , action: 'edit'},
                    }" 
                     
                >
                    <button  v-if="checkPermission('package.edit')" class="btn btn-sm rounded-xl normal-case"
                    v-tippy="{ content: 'Configure Package' }"
                    :class="{
                                'btn-primary': !tenant_package.lock && !buttonVisible,
                                'btn-outline border-none': tenant_package.lock,
                                'disabled-button': tenant_package.lock,
                                'buttonvisbility border-none':buttonVisible
                                }"
                        :style="{ color: tenant_package.lock ? 'black' : '' }"
                        @click="editPackage($event, tenant_package.id, 'edit')"
                        >
                    Configure
                    </button>
                    </router-link>
            </div>
                </div>
                <button @click="createAutomatedCase(tenant_package)" v-else
                    :disabled="!checkPermission('package.read') || !checkPermission('case.create') || !checkPermission('check.read') || !checkPermission('sequence.read')"
                    class="btn btn-sm rounded-xl no-animation normal-case btn btn-primary">
                    <Loader :size="4" class="mr-2" v-if="tenant_package.isLoading || createCaseLoading" />
                    Create Case
                </button>
            </div>
        </div>
    </div>
</template>
<script>
const Loader = () => import("@/components/loader");
import { DateTime } from "luxon";
import { createNeotasCase } from "@shared/light-ray/pages/search-records/services"
// import {createNewNeotasCase} from "@shared/light-ray/services.js"
// import blackboxAxios from "@shared/light-ray/blackbox-axios"
import VueCookies from "vue-cookies";
import { checkPermission } from "@shared/utils/functions"
import axios from "@shared/light-ray/axios";
// import { default as rawAxios } from "axios";
import { default as rawAxios } from "@/axios";

export default {
    name: "package-card",
    components: { Loader },
    props: ["tenant_package", "progress", "edit"],
    data() {
        return {
            editVisible: null,
            buttonVisible:false,
            createCaseLoading: false,
        };
    },

    methods: {
        checkPermission,
        created_at_Date(tenant_package) {
            return tenant_package.created_at ?
                DateTime.fromJSDate(new Date(tenant_package.created_at)).toFormat("MM/dd/yyyy") :
                    '';
        },
        modified_at_Date(tenant_package) {
            return tenant_package.modified_at ?
                DateTime.fromJSDate(new Date(tenant_package.modified_at)).toFormat("MM/dd/yyyy") :
                    '';
        },
        handleChange() { this.editVisible = !this.editVisible },
        handleDocumentClick(event) {
            if (
                !this.$refs.dropdownButton?.contains(event.target) &&
                !document.querySelector("#dropdownDots").contains(event.target)
            ) {
                this.editVisible = false;
            }
        },
        async editPackage(event, package_id, option = null) {
            if (event.ctrlKey) {
                return;
            }
            if(this.tenant_package.lock == true && option == 'edit'){
                return
            }
            if(option == 'view'){
                this.$router.push({
                    name: "Screening Package View",
                    params: { packageName: package_id, action: "view" },
                });
                return
            }
            if(package_id == null){
                return
            }
            try {
                let payload = { lock_status: true }
                if (option == 'view') {
                    payload = { lock_status: false }
                }
                let url = `/screening-package/locked/${package_id}`;
                await rawAxios.post(url, payload);
                this.$router.push({
                    name: "Screening Package View",
                    params: { packageName: package_id, action: "edit" },
                });
                return true;
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to lock this package");
                return false; // Return false when there's an error
            }
        },
        openCaseCreateForm() {
            //this.$router.push({
            //name: "create-case-form",
            //params: { id: package_id },
            //});
        },
        async caseCreate(tenant_package) {
            if (tenant_package?.automated) {
                const payload = {
                    due_diligence_level: process.env.VUE_APP_NEOTAS_PRODUCT_NAME,
                    self_service: true
                }
                const { data } = await createNeotasCase(payload)
                VueCookies.set("neotas_case", data.case_id)
                this.$store.dispatch("setNeotasCaseId", data.case_id)
                this.$router.push({
                    name: "search-company-records",
                    params: { neotasCase: data.case_id, type: "company-search" },
                    query: { package_id: tenant_package.id, client_id: this.$route.query.client }
                })
            }
            else {
                this.$emit("create", tenant_package);
            }
        },

        async createAutomatedCase(tenant_package) {
            if (tenant_package?.automated) {
                const payload = {
                    client_id: this.$route.query.client
                }
                try {
                    const url = `/user/saas-token`;
                    const data = await axios.post(url, payload);
                    VueCookies.set("saas-token", data.data.token);

                    sessionStorage.removeItem("neo_case_id");
                    sessionStorage.removeItem("id");
                    sessionStorage.removeItem("case_name");


                    sessionStorage.setItem("package_id", tenant_package.id);
                    localStorage.setItem("package_name", tenant_package.package_name);
                    sessionStorage.setItem("client_id", this.$route.query.client);

                    let casePayload = { package_id: tenant_package.id };
                    await this.$store.dispatch("createNewCase", casePayload, payload.client_id);
                    this.createCaseLoading = false;

                } catch (err) {
                    this.createCaseLoading = false;
                    console.error(err);
                    this.$toast(err?.response?.data?.details || "something went wrong")
                }


            }
            else {
                this.createCaseLoading = false;
                this.$emit("create", tenant_package);
            }
            this.createCaseLoading = false;
        },
        showCloneModal(tenant_package) {
            this.$emit("showCloneModal", tenant_package);
        },
    },
    mounted() {
        // Add a click event listener to the document
        document.addEventListener("click", this.handleDocumentClick);
    },
    beforeDestroy() {
        // Remove the click event listener when the component is destroyed
        document.removeEventListener("click", this.handleDocumentClick);
    },
};
</script>
<style scoped lang="scss">
.loader {
    border-color: #ffffff !important;
    // border-bottom-color: #0d69d5 !important;
    border-bottom-color: var(--theme-color-primary-300) !important;
    border-width: 3px !important;
}

.threedot {
    margin-left: -60px;
}

.card-size {
    height: max-content;
}

.timeblock {
    //    margin-right: auto;
    margin-left: auto;
}

.disabled-button {
  background-color: #dddddd; /* Change the background color to gray */
  color: white; /* Change the text color to white */
//   cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}
.buttonvisbility{
    color: white;
    background-color: white;
    cursor: not-allowed
}
</style>
